<template>
    <div>
      <div id="wrap">
        <swiper :slides-per-view="5" :space-between="50" ref="mySwiper" @slideChange="slideChange">
          <swiper-slide>
            <img src="@/assets/img/card/xtandi/StrikeNow/slide1.jpg" alt="" />
            <a class="link" id="link1" target="(link1)" @click="targetLinkClick('link1')"> </a>
          </swiper-slide>
       
        </swiper>
      </div>
    </div>
  </template>
  <script>
  import 'swiper/dist/css/swiper.css';
  import { swiper, swiperSlide } from 'vue-awesome-swiper';
  
  import $ from 'jquery';
  export default {
    components: {
      swiper,
      swiperSlide,
    },
    // 추가
    data() {
      return {
        content: '엑스탄디 1차 카드뉴스(적응증 및 가격)',
      };
    },
    mounted() {
      this._setFirstEvent();
      console.log('test');
    },
    methods: {
      // 추가 // 4자리 인증후 page 0 저장
      _setFirstEvent() {
        console.log('test');
        this.makeLink();
      },
      makeLink() {
        const scale = $(wrap).width() / 768;
  
        const link1 = $('#link1');
        link1.css('position', 'absolute');
        link1.css('top', scale * 1030);
        link1.css('right', scale * 1);
        link1.css('width', scale * 320);
        link1.css('height', scale * 76);
  
      
  
        this.resizeEvent();
        window.addEventListener('resize', this.resizeEvent);
  
        // 추가
        this.slideChange();
  
        setTimeout(() => {
          this.setUserScalable();
        }, 1000);
      },
      resizeEvent() {
        const scale = $(wrap).width() / 768;
  
        const link1 = $('#link1');
        link1.css('position', 'absolute');
        link1.css('top', scale * 1030);
        link1.css('right', scale * 1);
        link1.css('width', scale * 320);
        link1.css('height', scale * 76);
  
       
      },
      targetLinkClick(link) {
        let url =
          'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=201307883aupdateTs2023-05-25%2015:44:14.0b';
  
        // 추가
        this.slideChange(link);
        window.open(url, '_blank');
      },
      // 추가
      setUserScalable() {
        const viewportMeta = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
          viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
        }
      },
      // 추가
      slideChange(link) {
        let page = this.$refs.mySwiper !== undefined ? this.$refs.mySwiper.swiper.activeIndex : 0;
        let tLink = link === undefined ? '' : link;
        const d = {
          content: this.content,
          page,
          tLink,
        };
        this.$emit('slideChange', d);
      },
    },
  };
  </script>
  <style lang="scss">
  @charset "utf-8";
  
  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    outline: 0 none;
    padding: 0;
    font-family: 'Noto Sans KR', '돋움', 'Dotum', 'Apple Gothic', sans-serif;
    vertical-align: top;
  }
  
  html {
    width: 100%;
    height: 100%;
  }
  
  body {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1;
    color: #333;
    font-weight: 400;
    word-break: break-all;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-size: 100%;
  }
  
  nav ul,
  ul,
  ol,
  li {
    list-style: outside none none;
  }
  
  img {
    border: 0 none;
    vertical-align: top;
  }
  
  fieldset {
    border: 0 none;
  }
  
  a:link {
    text-decoration: none;
    color: #333;
  }
  
  a:visited {
    text-decoration: none;
    color: #333;
  }
  
  a:hover {
    text-decoration: none;
    color: #333;
  }
  
  a:active {
    text-decoration: none;
    color: #333;
  }
  
  * {
    -webkit-text-size-adjust: none;
  }
  
  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    background-color: lightgray;
    /*-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;*/
  }
  
  /* clear */
  .clear {
    zoom: 1;
  }
  
  .clear:after {
    content: '';
    display: block;
    clear: both;
  }
  
  /* ir */
  .ir_blind {
    height: 0;
    width: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
  }
  
  #wrap {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  #wrap .swiper-container {
    overflow: visible;
  }
  
  #wrap .swiper-container img {
    width: 100%;
  }
  
  #wrap .por {
    position: relative;
  }
  
  #wrap .btns {
    text-indent: -9999px;
    position: absolute;
    display: block;
  }
  
  #wrap .btn01 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  
  #wrap .btn02 {
    width: 45%;
    height: 13vw;
    bottom: 43vw;
    left: 50%;
  }
  
  @media only screen and (min-width: 768px) {
    #wrap {
      width: 768px;
    }
  }
  
  .player {
    z-index: 5;
  }
  
  /* button{z-index: 10;} */
  
  a.link {
    opacity: 0;
    background-color: transparent;
    z-index: 5;
  }
  
  .hcpBox {
    width: 100%;
    height: calc(100vh - 87px);
    background: white;
    text-align: center;
    padding: 150px 0;
  }
  </style>
  