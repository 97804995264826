<template>
  <div>
    <div id="wrap">

      <swiper :slides-per-view="5" :space-between="50" ref="mySwiper" @slideChange="slideChange">
        <swiper-slide>
          <img src="@/assets/img/card/harnalD/250205cardnews/slide1.jpg" alt="" />
          <a class="link" id="link1" target="(link1)" @click="targetLinkClick('link1')"> </a>
          <a class="link" id="link2" target="(link2)" @click="targetLinkClick('link2')"> </a>
          <a class="link" id="link3" target="(link3)" @click="targetLinkClick('link3')"> </a>
          <a class="link" id="link4" target="(link4)" @click="targetLinkClick('link4')"> </a>
          <a class="link" id="link5" target="(link5)" @click="targetLinkClick('link5')"> </a>
        </swiper-slide>

        <swiper-slide>
          <img src="@/assets/img/card/harnalD/250205cardnews/slide2.jpg" alt="" />
          <a class="link" id="link6" target="(link6)" @click="targetLinkClick('link6')"> </a>
          <a class="link" id="link7" target="(link7)" @click="targetLinkClick('link7')"> </a>
          <a class="link" id="link8" target="(link8)" @click="targetLinkClick('link8')"> </a>
          <a class="link" id="link9" target="(link9)" @click="targetLinkClick('link9')"> </a>
          <a class="link" id="link10" target="(link10)" @click="targetLinkClick('link10')"> </a>

        </swiper-slide>

      </swiper>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import $ from 'jquery';
export default {
  components: {
    swiper,
    swiperSlide,
  },
  // 추가
  data() {
    return {
      content: '하루날디 베타미가 2월 카드뉴스',
    };
  },
  mounted() {
    this._setFirstEvent();
    console.log('test');
  },
  methods: {
    // 추가 // 4자리 인증후 page 0 저장
    _setFirstEvent() {
      console.log('test');
      this.makeLink();
    },
    makeLink() {
      const scale = $(wrap).width() / 768;


      const $link1 = $('#link1');
      $link1.css('position', 'absolute');
      $link1.css('top', scale * 1045);
      $link1.css('right', scale * 340);
      $link1.css('width', scale * 75);
      $link1.css('height', scale * 75);

      const $link2 = $('#link2');
      $link2.css('position', 'absolute');
      $link2.css('top', scale * 1045);
      $link2.css('right', scale * 265);
      $link2.css('width', scale * 75);
      $link2.css('height', scale * 75);


      const $link3 = $('#link3');
      $link3.css('position', 'absolute');
      $link3.css('top', scale * 1045);
      $link3.css('right', scale * 190);
      $link3.css('width', scale * 75);
      $link3.css('height', scale * 75);

      const $link4 = $('#link4');
      $link4.css('position', 'absolute');
      $link4.css('top', scale * 1045);
      $link4.css('right', scale * 115);
      $link4.css('width', scale * 75);
      $link4.css('height', scale * 75);

      const $link5 = $('#link5');
      $link5.css('position', 'absolute');
      $link5.css('top', scale * 1045);
      $link5.css('right', scale * 35);
      $link5.css('width', scale * 80);
      $link5.css('height', scale * 75);

      const $link6 = $('#link6');
      $link6.css('position', 'absolute');
      $link6.css('top', scale * 1045);
      $link6.css('right', scale * 340);
      $link6.css('width', scale * 75);
      $link6.css('height', scale * 75);

      const $link7 = $('#link7');
      $link7.css('position', 'absolute');
      $link7.css('top', scale * 1045);
      $link7.css('right', scale * 265);
      $link7.css('width', scale * 75);
      $link7.css('height', scale * 75);


      const $link8 = $('#link8');
      $link8.css('position', 'absolute');
      $link8.css('top', scale * 1045);
      $link8.css('right', scale * 190);
      $link8.css('width', scale * 75);
      $link8.css('height', scale * 75);

      const $link9 = $('#link9');
      $link9.css('position', 'absolute');
      $link9.css('top', scale * 1045);
      $link9.css('right', scale * 115);
      $link9.css('width', scale * 75);
      $link9.css('height', scale * 75);

      const $link10 = $('#link10');
      $link10.css('position', 'absolute');
      $link10.css('top', scale * 1045);
      $link10.css('right', scale * 35);
      $link10.css('width', scale * 80);
      $link10.css('height', scale * 75);


      this.resizeEvent();
      window.addEventListener('resize', this.resizeEvent);

      // 추가
      this.slideChange();

      setTimeout(() => {
        this.setUserScalable();
      }, 1000);
    },
    resizeEvent() {
      const scale = $(wrap).width() / 768;


      const $link1 = $('#link1');
      $link1.css('position', 'absolute');
      $link1.css('top', scale * 1045);
      $link1.css('right', scale * 340);
      $link1.css('width', scale * 75);
      $link1.css('height', scale * 75);

      const $link2 = $('#link2');
      $link2.css('position', 'absolute');
      $link2.css('top', scale * 1045);
      $link2.css('right', scale * 265);
      $link2.css('width', scale * 75);
      $link2.css('height', scale * 75);


      const $link3 = $('#link3');
      $link3.css('position', 'absolute');
      $link3.css('top', scale * 1045);
      $link3.css('right', scale * 190);
      $link3.css('width', scale * 75);
      $link3.css('height', scale * 75);

      const $link4 = $('#link4');
      $link4.css('position', 'absolute');
      $link4.css('top', scale * 1045);
      $link4.css('right', scale * 115);
      $link4.css('width', scale * 75);
      $link4.css('height', scale * 75);

      const $link5 = $('#link5');
      $link5.css('position', 'absolute');
      $link5.css('top', scale * 1045);
      $link5.css('right', scale * 35);
      $link5.css('width', scale * 80);
      $link5.css('height', scale * 75);

      const $link6 = $('#link6');
      $link6.css('position', 'absolute');
      $link6.css('top', scale * 1045);
      $link6.css('right', scale * 340);
      $link6.css('width', scale * 75);
      $link6.css('height', scale * 75);

      const $link7 = $('#link7');
      $link7.css('position', 'absolute');
      $link7.css('top', scale * 1045);
      $link7.css('right', scale * 265);
      $link7.css('width', scale * 75);
      $link7.css('height', scale * 75);


      const $link8 = $('#link8');
      $link8.css('position', 'absolute');
      $link8.css('top', scale * 1045);
      $link8.css('right', scale * 190);
      $link8.css('width', scale * 75);
      $link8.css('height', scale * 75);

      const $link9 = $('#link9');
      $link9.css('position', 'absolute');
      $link9.css('top', scale * 1045);
      $link9.css('right', scale * 115);
      $link9.css('width', scale * 75);
      $link9.css('height', scale * 75);

      const $link10 = $('#link10');
      $link10.css('position', 'absolute');
      $link10.css('top', scale * 1045);
      $link10.css('right', scale * 35);
      $link10.css('width', scale * 80);
      $link10.css('height', scale * 75);


    },
    targetLinkClick(link) {
      let url =
          '';

      if (link === 'link1') {
        url =
            'https://nedrug.mfds.go.kr/pbp/ezdrug?itemSeq=200606210';
      }

      if (link === 'link2') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=201310989aupdateTs2023-09-07%2017:09:23.242738b';
      }

      if (link === 'link3') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=201310988aupdateTs2023-09-07%2017:09:13.597372b';
      }

      if (link === 'link4') {
        url =

            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=200703674aupdateTs2025-01-05%2015:41:19.0b';
      }

      if (link === 'link5') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=200703673aupdateTs2025-01-05%2015:41:29.0b';
      }

      if (link === 'link6') {
        url =
            'https://nedrug.mfds.go.kr/pbp/ezdrug?itemSeq=200606210';
      }

      if (link === 'link7') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=201310989aupdateTs2023-09-07%2017:09:23.242738b';
      }

      if (link === 'link8') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=201310988aupdateTs2023-09-07%2017:09:13.597372b';
      }

      if (link === 'link9') {
        url =

            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=200703674aupdateTs2025-01-05%2015:41:19.0b';
      }

      if (link === 'link10') {
        url =
            'https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetailCache?cacheSeq=200703673aupdateTs2025-01-05%2015:41:29.0b';
      }

      // 추가
      this.slideChange(link);
      window.open(url, '_blank');
    },
    // 추가
    setUserScalable() {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
      }
    },
    // 추가
    slideChange(link) {
      let page = this.$refs.mySwiper !== undefined ? this.$refs.mySwiper.swiper.activeIndex : 0;
      let tLink = link === undefined ? '' : link;
      const d = {
        content: this.content,
        page,
        tLink,
      };
      this.$emit('slideChange', d);
    },
  },
};
</script>
<style lang="scss">
@charset "utf-8";

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  outline: 0 none;
  padding: 0;
  font-family: 'Noto Sans KR', '돋움', 'Dotum', 'Apple Gothic', sans-serif;
  vertical-align: top;
}
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1;
  color: #333;
  font-weight: 400;
  word-break: break-all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
h1,
h2,
h3,
h4 {
  font-size: 100%;
}
nav ul,
ul,
ol,
li {
  list-style: outside none none;
}
img {
  border: 0 none;
  vertical-align: top;
}
fieldset {
  border: 0 none;
}
a:link {
  text-decoration: none;
  color: #333;
}
a:visited {
  text-decoration: none;
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #333;
}
a:active {
  text-decoration: none;
  color: #333;
}

* {
  -webkit-text-size-adjust: none;
}
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  background-color: lightgray; /*-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;*/
}
/* clear */
.clear {
  zoom: 1;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}
/* ir */
.ir_blind {
  height: 0;
  width: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
}
#wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
#wrap .swiper-container {
  overflow: visible;
}
#wrap .swiper-container img {
  width: 100%;
}

#wrap .por {
  position: relative;
}
#wrap .btns {
  text-indent: -9999px;
  position: absolute;
  display: block;
}
#wrap .btn01 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
#wrap .btn02 {
  width: 45%;
  height: 13vw;
  bottom: 43vw;
  left: 50%;
}

@media only screen and (min-width: 768px) {
  #wrap {
    width: 768px;
  }
}

.player {
  z-index: 5;
}
/* button{z-index: 10;} */

a.link {
  opacity: 0;
  background-color: transparent;
  z-index: 5;
}

.hcpBox {
  width: 100%;
  height: calc(100vh - 87px);
  background: white;
  text-align: center;
  padding: 150px 0;
}
</style>
